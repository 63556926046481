import * as React from "react";
import ReactApexChart from "react-apexcharts";
import {CHART_DEFAULT} from "../../config";


export const IndicatorChart = (props) => {
    return (
        <div className="card z-index-0">
            <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                <div className="bg-gradient shadow-primary border-radius-lg py-3 pe-1">
                    <div className="chart">
                        <ReactApexChart
                            options={props.options}
                            series={props.series}
                            height={300}
                            type="bar"
                        />
                    </div>
                </div>
            </div>
            <div className="card-body">
                <h6 className="mb-0 ">{props.name}</h6>
            </div>
        </div>
    )
}


const chunkify = (arr: Array, n: number) => {
    let chunk_list = []
     while(arr.length){
        chunk_list.push(arr.splice(0, n))
     }

     return chunk_list
}

export class ProjectChartSet extends React.Component {
    state = {
        chartSetData: []
    }
    constructor(props) {
        super(props);
    }

    contructChartData(projectIndicatorData){
        const result = projectIndicatorData.map((indicator, index) => {
            return {
                name: indicator.name,
                series: indicator.series,
                options: {
                    ...CHART_DEFAULT.options,
                    xaxis: {
                        categories: indicator.categories
                    }
                }
            }
        })

        return chunkify(result, 3)
    }
    fetchChartData() {
        const url = `${process.env.REACT_APP_API_URL}/stats/${this.props.title}/project_indicator`
        fetch(url, {
            headers: new Headers({
                "Authorization":`Bearer ${ localStorage.getItem("access")}`,
                'Content-Type': 'application/json'
            }),
            method: 'get',
            redirect: 'follow',
        })
        .then(response => response.json())
        .then(data_payload => {
            const chartData = this.contructChartData(data_payload.chart_data)
            console.log("setting the state")
            this.setState({
                chartSetData: chartData
            })
            console.log(this.state)
        })
    }

    componentDidMount() {
        console.log("mounting")
        this.fetchChartData()
    }

    render() {
        const chartSet = this.state.chartSetData
        console.log(chartSet)
        return (<>
                {chartSet.map((chartArray, rowNumber) => {
                    return (

                            <div className={"row mt-4"}>
                                {chartArray.map((chart, index) => {
                                    return (
                                        <div className={"col-lg-4 col-md-4 mt-4 mb-4"}>
                                            <IndicatorChart options={chart.options} series={chart.series} name={chart.name} />
                                        </div>
                                    )
                                })}

                        </div>

                    )
                })}
            </>

            )
    }

}

