import * as React from "react";
import {
    EditBase,
    TabbedForm,
    FormTab,
    TextInput,
    ReferenceInput,
    NumberInput,
    SelectInput,
} from 'react-admin';
import { RichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import { Card, CardHeader} from "@mui/material";


export const ObjectifEdit = (props) => (
    <EditBase disableAuthentication {...props}>
        <div>
            <Card>
                <CardHeader
                    title={"Modification d'un objectif"}
                />
                <TabbedForm>
                    <FormTab label={"Information générale"}>
                        <ReferenceInput source="project" reference={"project"}>
                            <SelectInput optionText={"name"} />
                        </ReferenceInput>

                        <TextInput source="title" label={"Titre de l'objectif"} />
                        <RichTextInput
                            source="description"
                            label={"Description"}
                            toolbar={<RichTextInputToolbar size="medium" />}
                        />

                        <NumberInput  source="targeted_value" label={"Valeur ciblée"} />
                    </FormTab>

                </TabbedForm>
            </Card>
        </div>
    </EditBase>
);
