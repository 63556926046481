import * as React from "react";
import {
    Create,
    TextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    SimpleForm
} from 'react-admin';
import { RichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import { Card, CardHeader} from "@mui/material";

export const ObjectifCreate = (props) => (
    <Create disableAuthentication {...props}>
        <div>
            <Card>
                <CardHeader
                    title={"Insertion d'un objectif"}
                />
                <SimpleForm>
                    <TextInput source="title" label={"Libelé"} />
                    <RichTextInput source="description"  label={"Description"} toolbar={<RichTextInputToolbar size="large" />} />

                    <ReferenceInput source="project"  reference={"project"} >
                        <SelectInput optionText={"name"} label={"Projet parent"} />
                    </ReferenceInput>
                    <DateInput source="start_date" label={"Date debut"} />
                    <DateInput  source="end_date" label={"Date fin"} />

                    <TextInput  source="targeted_value" label={"Valeur ciblée"} />
                </SimpleForm>
            </Card>
        </div>
    </Create>
);
