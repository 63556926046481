import * as React from 'react';
import { useState } from 'react';
import { useLogin, useNotify} from 'react-admin';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import 'animate.css';
import "../assets/css/login.css"

const LoginPage = ({ theme }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = e => {
        e.preventDefault();
        login({ username, password }).catch(() =>
            notify("Nom d'utilisateur ou mot de passe incorrect", {type: "error"})
        );
    };

    return (
        <div className={"login-page"}>
            <div id="signin">
                <div className="form-title">Identification</div>
                <div className="input-field">
                    <input type="email" id="email"
                           className={username ? "not-empty": ""}
                           onChange={event => setUsername(event.target.value)}
                           autoComplete="off"/>
                    <i className="material-icons">email</i>
                    <label htmlFor="email">Nom d'utilisateur</label>
                </div>
                <div className="input-field">
                    <input
                        type="password"
                        id="password"
                        className={password ? "not-empty": ""}
                        onChange={event => setPassword(event.target.value)}
                    />
                    <i className="material-icons">lock</i>
                    <label htmlFor="password">Mot de passe</label>
                </div>
                <a href="" className="forgot-pw">Mot de passe oublié ?</a>
                <button className="login" onClick={handleSubmit}>Se connecter <ArrowForwardIosIcon /> </button>
                <div className="check">
                    <i className="material-icons">check</i>
                </div>
            </div>
        </div>
    )
};

export default LoginPage;