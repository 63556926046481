// in src/posts.js
import {
 useShowController,
} from 'react-admin';

import * as React from "react";
import {BarChart} from "../chart/bar";
import {PieChart} from "../chart/pie";
import {GanttChart} from "../chart/gantt";
import {ProjectChartSet} from "../chart/project_indicator_chartset";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple} from "@fortawesome/free-solid-svg-icons";
import {Container, Paper} from "@mui/material";


export const ProjectShow = () => {
    const {record} = useShowController()

    return (<>
           <Container className={"mt-4"}>
               <Accordion>
                   <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                   >
                       <Typography>
                           <FontAwesomeIcon icon={faChartSimple} /> Indicateurs projet
                       </Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                       <div className={"container-fluid py-4"}>
                           <ProjectChartSet title={record.name} />
                       </div>
                   </AccordionDetails>
               </Accordion>
           </Container>
           <Container className={"mt-4"}>
               <div className="grid grid-cols-2 gap-4 justify-items-stretch mt-5">
                   <div className="">
                       <div className="card bg-base-100 shadow-xl">
                           <div className="card-body">
                               <h4 className="card-title">Répartition des entités</h4>
                               <BarChart title={record.name} />
                           </div>
                       </div>
                   </div>
                   <div className="">
                       <div className="card bg-base-100 shadow-xl">
                           <div className="card-body">
                               <h4 className="card-title">Repartition globale des entités</h4>
                               <PieChart title={record.name} />
                               <div className="card-actions justify-end">
                                   <span>Powered by Kouritech</span>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </Container>
           <Container className={"mt-4"}>
               <Paper elevation={12}>
                   <h2>Suivi des activités</h2>
                   <GanttChart project={record}/>
               </Paper>
           </Container>
        </>
    )
}
