// in src/posts.js
import {
    ChipField,
    CreateButton,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    List,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField,
    TextInput, useTranslate,
} from 'react-admin';
import {Box, Typography, Chip} from '@mui/material';


const QuickFilter = ({ label }) => {
    const translate = useTranslate();
    return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            Aucune activité enregistrée
        </Typography>
        <Typography variant="body1">
            Ajouter une activité
        </Typography>
        <CreateButton variant={"outlined"}/>
    </Box>
);

const activityFilter = [
        <TextInput label="Titre" source="title__icontains"/>,
        <DateInput label={"Date de début"} source={"start_date__gte"} />,
        <DateInput label={"Date de fin"} source={"end_date__lte"} />,
        <QuickFilter label={"in_progress"} source={"state"} defaultValue={"in_progress"} />
    ]

export const ActivityList = props => (
    <List empty={<Empty/>} filters={activityFilter}   >
        <Datagrid>
            <TextField source="title" label={"Intitulé de l'activité"}/>
            <DateField source="start_date" label={"Date debut"}/>
            <DateField source="end_date" label={"Date fin"}/>
            <ReferenceField source="objectif" label={"Objectif"} reference={"objectif"}>
                <TextField source={"title"} label={"Objectif"}/>
            </ReferenceField>
            <ReferenceArrayField source="actors" reference="user">
                <SingleFieldList>
                    <ChipField source="username"/>
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="report_frequency" label={"Frequence des rapports"}/>
            <TextField source="state" label={"Etat"}/>
            <EditButton/>
        </Datagrid>
    </List>
);
