import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ReactApexChart from "react-apexcharts";
import {CHART_DEFAULT} from "../../config";


export class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.setActivityList.bind(this)
        this.setColumnChartData.bind(this)
        this.state = {
            activites: [],
            ...CHART_DEFAULT,
            chart: {
                type: 'bar',
                height: 450
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '65%',
                    endingShape: 'rounded'
                },
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: 'Personne(s)'
                }
            },
            fill: {
                opacity: 1
            },
        }
    }

    setActivityList(){
        const url = `${process.env.REACT_APP_API_URL}/activity/?project=${this.props.title}`
        fetch(url, {
            headers: new Headers({
                "Authorization":`Bearer ${ localStorage.getItem("access")}`,
                'Content-Type': 'application/json'
            }),
            method: 'get',
            redirect: 'follow',
        })
            .then(data => data.json())
            .then(json => {
                this.setState({
                    ...this.state,
                    activities: json.results.map(activity =>  {return {id: activity.id, label: activity.title}})
                })
            })
    }
    setColumnChartData(activity){
        const url = `${process.env.REACT_APP_API_URL}/stats/${this.props.title}/column?activity=${activity.id}`
        fetch(url, {
            headers: new Headers({
                "Authorization":`Bearer ${ localStorage.getItem("access")}`,
                'Content-Type': 'application/json'
            }),
            method: 'get',
            redirect: 'follow',
        })
            .then(data => data.json())
            .then(json => {
                this.setState({
                    series: json.series,
                    options: {
                        ...this.state.options,
                        xaxis: {
                            categories: json.labels
                        }
                    }
                })
            })
        }

    componentDidMount() {
        this.setActivityList()
    }

    render() {
        return (<>
                <Autocomplete
                    id="combo-box"
                    options={this.state.activities}
                    sx={{ width: 300 }}
                    onChange={
                        (event, value) => this.setColumnChartData(value)}
                    renderInput={(params) => <TextField {...params} label="Activité" />}
                />
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    height={300}
                    type="bar"
                />
            </>
        )
    }
}
