import * as React from "react";
import {DateInput, Edit, NumberInput, SimpleForm, ReferenceInput, SelectInput, TextInput} from 'react-admin';
import {Card, CardHeader} from "@mui/material";

export const IndicatorEdit = (props) => (
    <Edit {...props}>
        <div>
            <Card>
                <CardHeader
                    title={"Modification d'un indicateur"}
                />
                <SimpleForm>
                    <ReferenceInput source={"activity"} reference={"activity"} >
                        <SelectInput aria-readonly={true} optionText={"title"} contentEditable={false} />
                    </ReferenceInput>
                    <TextInput source="label" label={"Libélé"} />
                    <NumberInput source="value" label={"Nombre"}  />
                    <NumberInput label={"Age moyenne"} source={"age_mean"} />
                    <DateInput source="start_date" label={"Début période"} />
                    <DateInput  source="end_date" label={"Fin période"} />
                    <SelectInput source={"state"} choices={[
                        {id: "completed", name: "Completé"}
                    ]}
                     options={{
                         value: 'completed',
                         disableEmpty: true
                     }}
                    />
                </SimpleForm>
            </Card>
        </div>
    </Edit>
);
