import * as React from "react";
import {
    CreateBase,
    TextInput,
    DateInput,
    SelectInput,
    ReferenceInput,
    ReferenceArrayInput,
    SelectArrayInput,
    TabbedForm,
    FormTab, ArrayInput, SimpleFormIterator,
} from 'react-admin';
import { RichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import { Card, CardHeader} from "@mui/material";

export const ActivityCreate = (props) => (
        <CreateBase disableAuthentication {...props}>
            <div>
                <Card>
                    <CardHeader
                    title={"Enregistrement d'une activité"}
                    />
                    <TabbedForm>
                        <FormTab label={"Projet"}>
                            <ReferenceInput source="objectif" reference={"objectif"} >
                                <SelectInput optionText={"title"} label={"Objectif parent"} />
                            </ReferenceInput>
                        </FormTab>
                        <FormTab label={"Responsables"}>
                            <ReferenceArrayInput source="actors" reference="user">
                                <SelectArrayInput optionText={record => `${record.username} (${record.first_name} ${record.last_name})` } />
                            </ReferenceArrayInput>
                        </FormTab>
                        <FormTab label={"Informations"}>
                            <TextInput source="title" label={"Intitulé de l'activité"} />
                            <RichTextInput
                                source="description"
                                label={"Description"}
                                toolbar={<RichTextInputToolbar size="medium" />}
                            />
                            <DateInput source="start_date" label={"Date de début"} />
                            <DateInput  source="end_date" label={"Date de fin"} />
                            <TextInput  source="zone" label={"Zone ciblée"} />
                            <SelectInput  source="report_frequency" label={"Fréquence des indicateurs"} choices={[
                                { id: 'weekly', name: 'Hebdomadaire' },
                                { id: 'monthly', name: 'Mensuel' },
                                { id: 'semestrial', name: 'Semestriel' },
                                { id: 'annual', name: 'Annuel' }
                            ]}
                            />
                        </FormTab>
                        <FormTab label={"Bénéficiaire"}>
                            <ArrayInput source="recipients" >
                                <SimpleFormIterator>
                                    <TextInput source="first_name" />
                                    <TextInput source="last_name" />
                                    <TextInput source="phone_number" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </FormTab>
                    </TabbedForm>
                </Card>
            </div>
        </CreateBase>
);
