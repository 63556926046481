import * as React from "react";
import {
    EditBase,
    TabbedForm,
    FormTab,
    TextInput,
    DateInput,
    NumberInput, SimpleFormIterator, ArrayInput,

} from 'react-admin';
import { RichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import { Card, CardHeader} from "@mui/material";

export const ProjectEdit = (props) => (
    <EditBase disableAuthentication {...props}>
        <div>
            <Card>
                <CardHeader
                    title={"Modification d'un projet"}
                />
                <TabbedForm>
                    <FormTab label={"Resumé"}>
                        <TextInput source="name" label={"Nom"} />
                        <RichTextInput
                            source="summary"
                            label={"Resumé"}
                            toolbar={<RichTextInputToolbar size="large" />}

                        />
                        <NumberInput source="budget" label={"Budget"}  />
                        <DateInput source="start_date" label={"Date debut"} />
                        <DateInput  source="end_date" label={"Date fin"} />
                        <TextInput  source="localization" label={"Zone ciblé"} />
                    </FormTab>
                    <FormTab label={"Indicateur"}>
                        <ArrayInput source="indicators" >
                            <SimpleFormIterator >
                                <TextInput source="name" label={"Intitulé"} />
                                <TextInput source="target_value" label={"Valeur cible"}/>
                                <TextInput source="reached_value" label={"Valeur atteinte"} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>

                </TabbedForm>
            </Card>
        </div>
    </EditBase>
);
