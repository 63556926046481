import * as React from "react";
import {CreateBase,
    SimpleForm,
    TextInput,
    DateInput,
    NumberInput,

    } from 'react-admin';
import { RichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import { Card, CardHeader} from "@mui/material";

export const ProjectCreate = (props) => (
    <CreateBase disableAuthentication {...props}>
        <div>
            <Card>
                <CardHeader
                title={"Creation d'un projet"}
                />
                <SimpleForm>
                    <TextInput source="name" label={"Nom"} />
                    <RichTextInput
                        source="summary"
                        label={"Resumé"}

                        toolbar={<RichTextInputToolbar size="large" />}

                    />
                    <NumberInput source="budget" label={"Budget"}  />
                    <DateInput source="start_date" label={"Date debut"} />
                    <DateInput  source="end_date" label={"Date fin"} />
                    <TextInput  source="localization" label={"Zone ciblé"} />
                </SimpleForm>
            </Card>
        </div>
    </CreateBase>
);
