// in src/posts.js
import {
    List,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    CreateButton,
    EditButton, ShowButton
} from 'react-admin';
import { Box, Typography } from '@mui/material';

const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            Aucune ressource disponible
        </Typography>
        <CreateButton  variant={"outlined"}/>
    </Box>
);


export const ProjectList = (props) => (
    <List empty={<Empty />} disableAuthentication {...props}>
        <Datagrid>
            <TextField source="name" label={"Nom"}/>
            <NumberField source="budget" label={"Budget"} options={{style: "currency", currency: "XOF"}} />
            <DateField source="start_date" label={"Date debut"}/>
            <DateField source="end_date" label={"Date fin"}/>
            <TextField source="localization" label={"Zone ciblé"}/>
            <EditButton />
            <ShowButton />
        </Datagrid>
    </List>
);
