// in src/posts.js
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    ReferenceField,
    CreateButton,
} from 'react-admin';
import {Box, Typography} from '@mui/material';

const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            Aucun objectif enregistré
        </Typography>
        <Typography variant="body1">
            Ajouter un objectif
        </Typography>
        <CreateButton  variant={"outlined"}/>
    </Box>
);


export const ObjectifList = (props) => (
    <List empty={<Empty />} disableAuthentication {...props}>
        <Datagrid>
            <TextField source="title" label={"Intitulé de l'objectif"}/>
            <ReferenceField source="project" label={"Projet"} reference={"project"}>
                <TextField source={"name"} label={"Projet parent"} />
            </ReferenceField>
            <TextField source={"targeted_value"} label={"Valeur ciblée"} />
            <EditButton />
        </Datagrid>
    </List>
);
