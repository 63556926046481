import * as React from "react";
import {Gantt, ViewMode} from "gantt-task-react";


export class GanttChart extends React.Component {
    constructor(props) {
        super(props);
        this.handleViewChange.bind(this)

        this.state = {
            viewMode: ViewMode.Week,
            tasks: [{
                start: new Date(2020, 1, 1),
                end: new Date(2020, 1, 2),
                name: 'Idea',
                id: 'Task 0',
                type: 'task',
                progress: 45,
                isDisabled: true,
                styles: {progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d'},
            }]
        }
    }

    componentDidMount() {
        const url = `${process.env.REACT_APP_API_URL}/stats/${this.props.project.name}/gantt`

        fetch(url, {
            headers: new Headers({
                "Authorization":`Bearer ${ localStorage.getItem("access")}`,
                'Content-Type': 'application/json'
            }),
            method: 'get',
            redirect: 'follow',
        })
            .then(data => data.json())
            .then(json => {
                // eslint-disable-next-line
                json.tasks.map(e => {
                    e.start = new Date(e.start)
                    e.end = new Date(e.end)
                })
                this.setState({
                    ...this.state,
                    tasks: json.tasks
                })
            })
    }

    handleViewChange(event){
        this.setState({
            ...this.state,
            viewMode: event
        })
    }

    render() {
        return (
            <div className={"Wrapper"}>
                <div className={"btn-group mb-5"}>
                    <button className="btn btn-success" onClick={() =>  this.handleViewChange(ViewMode.Day)}>
                        Jour
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={() => this.handleViewChange(ViewMode.Week)}
                    >
                        Semaine
                    </button>
                    <button
                        className="btn btn-success"
                        onClick={() =>  this.handleViewChange(ViewMode.Month)}
                    >
                        Mois
                    </button>
                </div>
                <Gantt
                    tasks={this.state.tasks}
                    viewMode={this.state.viewMode}
                    locale={"fra"}
                    ganttHeight={300}
                    columnWidth={65}
                />
            </div>
        )
    }
}