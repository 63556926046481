// in src/posts.js
import {
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    List,
    NumberField,
    ReferenceField,
    FunctionField,
    TextField,
    FilterLiveSearch
} from 'react-admin';

import WomanIcon from '@mui/icons-material/Woman';
import ManIcon from '@mui/icons-material/Man';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import {Box, CardContent, Typography} from '@mui/material';
import {Card} from "@material-tailwind/react";
import {StateFilter} from "./filter";

const Empty = () => (
    <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
            Aucune activité enregistrée
        </Typography>
        <Typography variant="body1">
            Ajouter une activité
        </Typography>
        <CreateButton  variant={"outlined"}/>
    </Box>
);


export const FilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
            marginRight: '1em',
            marginTop: '4em'
        }}
    >
        <Card>
            <CardContent>
                <FilterLiveSearch source={"label"} />
                <StateFilter />
            </CardContent>
        </Card>
    </Box>
);


export const IndicatorList = (props) => (
    <List empty={<Empty />} aside={<FilterSidebar />}>
        <Datagrid>
            <TextField source="label" label={"Libélé indicateur"}/>
            <FunctionField
                label="Entity"
                render={record => {
                    return record.entity.toLowerCase() === "femme" ?
                        <WomanIcon fontSize={"large"} /> : record.entity.toLowerCase() === "homme" ?
                            <ManIcon fontSize={"large"} /> :<AccessibleForwardIcon fontSize={"large"} />
                }}
            />
            <FunctionField source="age_mean" label={"Moyenne d'age"} render={record => `${record.age_mean} ans`} />
            <NumberField source="value" label={"Nombre"} />
            <DateField
                source="start_date"
                label={"Début"}
                locales="fr-FR"
                options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
            />
            <DateField
                source="end_date"
                label={"Fin"}
                locales="fr-FR"
                options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }}
            />
            <ReferenceField source="activity" label={"Activité"} reference={"activity"}>
                <TextField source={"title"} />
            </ReferenceField>
            <TextField label="Etat" source={"state"} />
            <EditButton variant={"contained"} label={""}/>
        </Datagrid>
    </List>
);
