import {FilterList, FilterListItem} from "react-admin";

export const StateFilter = () => (
    <FilterList
        label="Etat"
    >
        <FilterListItem
            label="Completé"
            value={{
                state: "completed",
            }}
        />
        <FilterListItem
            label="Incomplet"
            value={{
                state: "in_progress",
            }}
        />
    </FilterList>
)

export const ActivityFilter = () => (
    <FilterList
        label="Activité"
    >
        <FilterListItem
            label="Completé"
            value={{
                state: "completed",
            }}
        />
        <FilterListItem
            label="Incomplet"
            value={{
                state: "to_complete",
            }}
        />
    </FilterList>
)
