import drfProvider, {fetchJsonWithAuthJWTToken} from 'ra-data-django-rest-framework';
import {fetchUtils} from "ra-core";

const provider = drfProvider(`${process.env.REACT_APP_API_URL}`, fetchJsonWithAuthJWTToken);
const httpClient = fetchUtils.fetchJson

const dataProvider = {
    ...provider,
    update: async (resource, params) => {
        if (resource !== "activity") {
            return provider.update(resource, params)
        }

        let fileData = new FormData()
        fileData.append("json_data", JSON.stringify(params.data))

        for(let file of params.data.files){
            fileData.append(file.path.title, file.path.rawFile)
        }

        const { json } = httpClient(`${process.env.REACT_APP_API_URL}/${resource}/${params.id}/`, {
            method: 'PATCH',
            enctype: 'multipart/form-data',
            body: fileData,
        });
        return {data: json};
    }
}
export default dataProvider
