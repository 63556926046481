import * as React from "react";
import {
    Edit,
    TabbedForm,
    TextInput,
    DateInput,
    ArrayInput,
    FileInput,
    SelectInput,
    NumberInput,
    FileField,
    SimpleFormIterator,
    FormTab
} from 'react-admin';
import { RichTextInput, RichTextInputToolbar} from 'ra-input-rich-text';
import {Card, CardHeader} from "@mui/material";

export const ActivityEdit = (props) => (
    <Edit disableAuthentication mutationMode={"optimistic"}  {...props}>
        <div>
            <Card>
                <CardHeader
                    title={"Modification d'une activité"}
                />
                <TabbedForm>
                    <FormTab label={"Informations"}>
                        <TextInput source="title" label={"Intitulé de l'activité"} />
                        <RichTextInput
                            source="description"
                            label={"Description"}
                            toolbar={<RichTextInputToolbar size="medium" />}
                        />
                        <DateInput source="start_date" label={"Date de début"} />
                        <DateInput  source="end_date" label={"Date de fin"} />
                        <TextInput  source="zone" label={"Zone ciblée"} />
                        <NumberInput source={"targeted_value"} label={"Valeur cible"} />
                        <SelectInput  source="state" label={"Etat"} choices={[
                            { id: 'completed', name: 'Achevé' },
                            { id: 'in_progress', name: 'En cours' },
                        ]}
                        />
                    </FormTab>
                    <FormTab label={"Bénéficiaires"}>
                        <ArrayInput source="recipients" >
                            <SimpleFormIterator >
                                <TextInput source="first_name" label={"Prénom"} />
                                <TextInput source="last_name" label={"Nom"}/>
                                <TextInput source="phone_number" label={"Numéro Portable"} />
                                <TextInput source="email" label={"E-mail"} />
                                <TextInput source="address" label={"Adresse"} />
                                <TextInput source="parent_phone_number" label={"Numéro parent"} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                    <FormTab label={"Artéfacts"}>
                        <ArrayInput source="files" >
                            <SimpleFormIterator disableRemove={true} >
                                <TextInput source="name" label={"Nom du fichier"} />
                                <SelectInput
                                    source="file_type"
                                    label={"Type de fichier"}
                                    choices={[
                                        {id: "Temoignage", name: "Temoignage"},
                                        {id: "Rapport", name: "Rapport"}
                                    ]}
                                />
                                <FileInput source="path" label={"Fichiers"}>
                                    <FileField source="path" title="title" />
                                </FileInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </FormTab>
                </TabbedForm>
            </Card>
        </div>
    </Edit>
);
