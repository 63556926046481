// in src/App.js
import * as React from "react";
import { Admin, Resource} from 'react-admin';
import dataProvider from "./provider/data.js"
import {jwtTokenAuthProvider} from "./provider/auth";
import LoginPage from "./components/loginPage";
import {ProjectList} from "./components/project/list";
import {ProjectCreate} from "./components/project/create";
import {ProjectEdit} from "./components/project/edit";
import {ActivityList} from "./components/activity/list";
import {ActivityCreate} from "./components/activity/create";
import {ActivityEdit} from "./components/activity/edit";
import {ObjectifList} from "./components/objectif/list";
import {ObjectifCreate} from "./components/objectif/create";
import {ObjectifEdit} from "./components/objectif/edit";
import {IndicatorCreate} from "./components/indicator/create";
import {IndicatorList} from "./components/indicator/list";
import {IndicatorEdit} from "./components/indicator/edit";
import DashBoard from "./components/dashboard";
import {ProjectShow} from "./components/project/show";
import frenchMessages from 'ra-language-french';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import RadarIcon from '@mui/icons-material/Radar';
import "gantt-task-react/dist/index.css";

const messages = {
    'fr': frenchMessages,
};
const i18nProvider = polyglotI18nProvider(locale => messages["fr"]);

const App = () => (
    <Admin
        dashboard={DashBoard}
        loginPage={LoginPage}
        dataProvider={dataProvider}
        authProvider={jwtTokenAuthProvider}
        i18nProvider={i18nProvider}
    >
        <Resource
            name="project"
            list={ProjectList}
            create={ProjectCreate}
            edit={ProjectEdit}
            show={ProjectShow}
        />
        <Resource
            name="activity"
            icon={TaskAltIcon}
            list={ActivityList}
            create={ActivityCreate}
            edit={ActivityEdit}
        />
        <Resource
            name="objectif"
            icon={RadarIcon}
            list={ObjectifList}
            create={ObjectifCreate}
            edit={ObjectifEdit}
        />
        <Resource
            name="indicator"
            icon={TrendingUpIcon}
            list={IndicatorList}
            create={IndicatorCreate}
            edit={IndicatorEdit}
        />

    </Admin>

)
export default App;