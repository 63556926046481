import * as React from "react";
import ReactApexChart from "react-apexcharts";


export class PieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options: {
                chart: {
                    type: 'donut',
                    width: 580
                },
                legend: {
                    position: "bottom"
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true
                                }
                            }
                        }
                    }
                },
            },
        };
    }

    componentDidMount() {
        const url = `${process.env.REACT_APP_API_URL}/stats/${this.props.title}/doughnut`

        fetch(url, {
            headers: new Headers({
                "Authorization":`Bearer ${ localStorage.getItem("access")}`,
                'Content-Type': 'application/json'
            }),
            method: 'get',
            redirect: 'follow',
        })
            .then(data => data.json())
            .then(json => {
                this.setState({
                    series: json.series,
                    options: {
                        ...this.state.options,
                        labels:  json.labels
                    }
                })
            })
    }

    render() {
        return (
            <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                height={350}
                type={"donut"}
            />
        )
    }
}