import * as React from "react";
import {
    Create,
    DateInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify, useRedirect,
} from 'react-admin';
import {Card, CardHeader} from "@mui/material";

export const IndicatorCreate = (props) => {
    const notify = useNotify()
    const redirect = useRedirect()

    const onSuccess = () => {
        notify("Indicateur crée avec succès", {type: "success"});
        redirect(`/indicator`);
    }

    return (
        <Create mutationOptions={{ onSuccess }} disableAuthentication {...props}>
            <div>
                <Card>
                    <CardHeader
                        title={"Insertion d'un indicateur"}
                    />
                    <SimpleForm>
                        <TextInput source="label" label={"Libélé"}/>
                        <SelectInput source={"entity"} choices={[
                            {id: "Femme", value: "Femme"},
                            {id: "Homme", value: "Homme"},
                            {id: "Handicapé", value: "Handicapé"},
                        ]} optionText={"id"} optionValue={"value"} />
                        <NumberInput source="value" label={"Nombre"} />
                        <NumberInput label={"Age moyenne"} source={"age_mean"} />
                        <DateInput source="start_date" label={"Début période"}/>
                        <DateInput source="end_date" label={"Fin période"}/>
                        <ReferenceInput reference={"activity"} label={"Activité parent"} source={"activity"}>
                            <SelectInput optionText={"title"}/>
                        </ReferenceInput>
                    </SimpleForm>
                </Card>
            </div>
        </Create>
    )
};
